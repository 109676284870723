<template>
  <v-footer class="Footer" app>
    <v-col
      class="text-center"
      cols="12"
    >
      2021 - {{ new Date().getFullYear() }} — <strong>Dan Assaban</strong>
    </v-col>
  </v-footer>
</template>

<script>
export default {
name: 'Footer',
}

</script>

<style>

</style>